require('morris.js/morris.js');

$(document).on('turbolinks:load', function () {
    var ordersChart = document.getElementById('orders_chart');
    var comments_chart = document.getElementById('comments_chart');
    var location_suggestions_chart = document.getElementById('location_suggestions_chart');
    var new_users_chart = document.getElementById('new_users_chart');

    if (ordersChart) {
        console.log("orderchart", JSON.parse(ordersChart.dataset.orders))
        new Morris.Line({
            element: 'orders_chart',
            data: JSON.parse(ordersChart.dataset.orders),
            xkey: 'created_at',
            ykeys: ['price'],
            labels: ['Ordertotal för månad']
        });
    }

    if (comments_chart) {
        new Morris.Line({
            element: 'comments_chart',
            data: JSON.parse(comments_chart.dataset.comments),
            xkey: 'created_at',
            ykeys: ['comments'],
            labels: ['Kommentarer för månad']
        });
    }

    if (location_suggestions_chart) {
        new Morris.Line({
            element: 'location_suggestions_chart',
            data: JSON.parse(location_suggestions_chart.dataset.locationSuggestions),
            xkey: 'created_at',
            ykeys: ['location_suggestions'],
            labels: ['Platsdefinitioner för månad']
        });
    }

    if (new_users_chart) {
        new Morris.Line({
            element: 'new_users_chart',
            data: JSON.parse(new_users_chart.dataset.users),
            xkey: 'created_at',
            ykeys: ['users'],
            labels: ['Nya medlemmar för månad']
        });
    }
});