$(document).on('turbolinks:load', function () {
    var adminSearchResultsHolder = document.getElementById('admin-search-results-holder');
    var adminSearchResults = document.getElementById('admin-search-results');

    if ($('#admin-search')) {
        $('#admin-search').change(function (event) {
            console.log("event", event.target.value);
            // TODO: debounce here to improve performence
            $.ajax({
                url: '/admin/search?q=' + event.target.value,
                type: 'GET',
                dataType: 'json',
                contentType: 'application/json; charset=utf-8',
                success: function (result) {
                    // CallBack(result);
                    console.log("result", result);

                    if (result && result.albums) {
                        let template = [];
                        result.albums.forEach((item) => {
                            template += '<a href="/serier/' + item.id + '"><p><span class="badge badge-primary">Album</span> ' + item.name + ' - ' + item.group + '<br/><span>' + item.area + '</span></p></a>'
                        });
                        if (result.photos) {
                            result.photos.forEach((item) => {
                                template += '<div><p><span class="badge badge-success">Bild</span> <a href="/bilder/' + item.id + '">' + item.name + '</a><br/><span><i class="fas fa-comment"></i>' + item.comments_count + '</span> | <a id="img-btn-' + item.id + '"><i class="far fa-image"></i></a></p></div>'
                            });
                        }
                        adminSearchResults.innerHTML = template;
                        result.photos.forEach((item) => {
                            document.querySelectorAll('#img-btn-' + item.id).forEach(function (el) {
                                el.addEventListener('click', function (e) {
                                    e.preventDefault();
                                    BigPicture({
                                        el: e.target,
                                        imgSrc: item.image.span12 ? item.image.span12.url : item.image.url,
                                    })
                                })
                            });
                        });
                    }

                    if (result && result.albums.length < 1 || !event.target.value) {
                        adminSearchResultsHolder.classList.add('d-none')
                    } else {
                        adminSearchResultsHolder.classList.remove('d-none')
                    }

                },
                error: function (error) {
                    console.log("error", error)
                }
            });
        });
    }
});
