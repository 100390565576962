import bootbox from "./bootbox.min";

$(document).on('turbolinks:load', function () {
    var purchaseTermsConfirmCheckbox = document.getElementById('purchase_terms_confirm');
    var orderCreateBtn = document.querySelector('.ffh-order-create-btn');
    var orderNewFormEl = document.getElementById('new_order');
    var cancelMyOrderBtn = document.getElementById('cancel_my_order');
    var realCancelMyOrderBtn = document.getElementById('real_cancel_my_order');

    if (purchaseTermsConfirmCheckbox && orderCreateBtn) {
        purchaseTermsConfirmCheckbox.onclick = function () {
            orderCreateBtn.disabled = !purchaseTermsConfirmCheckbox.checked;
        }
    }

    if (orderCreateBtn && orderNewFormEl) {
        orderCreateBtn.addEventListener('click', function (e) {
            e.preventDefault();
            bootbox.confirm({
                title: 'Härmed bekräftar jag min order',
                message: 'Vid nästa steg så har du möjligheten att betala din order.',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Avbryt'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> Gå vidare'
                    }
                },
                callback: function (result) {
                    if (result === true) {
                        orderNewFormEl.submit();
                    }
                }
            })
        })
    }

    if (cancelMyOrderBtn) {
        cancelMyOrderBtn.addEventListener('click', function (e) {
            e.preventDefault();

            bootbox.confirm({
                title: 'Härmed bekräftar jag att avbryta min order',
                message: 'Detta går inte att ångra efter att du klickat på Gå vidare.',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Avbryt'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> Gå vidare'
                    }
                },
                callback: function (result) {
                    if (result === true) {
                        realCancelMyOrderBtn.click();
                    }
                }
            })
        })
    }

    var x13x18RealLinkEl = document.getElementById('13x18realLink');
    var x13x18BtnEl = document.getElementById('13x18btn');
    var x34x26RealLinkEl = document.getElementById('34x26realLink');
    var x34x26BtnEl = document.getElementById('34x26btn');
    var x40x305RealLinkEl = document.getElementById('40x305realLink');
    var x40x305BtnEl = document.getElementById('40x305btn');
    var x40x30RealLinkEl = document.getElementById('40x30realLink');
    var x40x30BtnEl = document.getElementById('40x30btn');

    var bootBoxContactPrintMessage = '<b>Observera:</b> <p class="mb-0"> Bilden är en kontaktkopia från ett förkommet negativ. <br/> Förstoringar kommer inte kunna levereras med samma detaljrikedom och skärpa som hade vi haft tillgång till originalnegativet. </p> <p class="mb-0 mt-4 mr-5"><span style="float:right">Jag beställer ändå:</span></p>';

    if (x13x18RealLinkEl && x13x18BtnEl) {
        x13x18BtnEl.addEventListener('click', function () {
            bootbox.confirm({
                title: 'Observera',
                message: bootBoxContactPrintMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Avbryt'
                    },
                    confirm: {
                        label: '<i class="fas fa-shopping-cart"></i> Lägg till i kundvagnen'
                    }
                },
                callback: function (result) {
                    if (result === true) {
                        x13x18RealLinkEl.click();
                    }
                }
            })
        })
    }
    if (x34x26RealLinkEl && x34x26BtnEl) {
        x34x26BtnEl.addEventListener('click', function () {
            bootbox.confirm({
                title: 'Observera',
                message: bootBoxContactPrintMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Avbryt'
                    },
                    confirm: {
                        label: '<i class="fas fa-shopping-cart"></i> Lägg till i kundvagnen'
                    }
                },
                callback: function (result) {
                    if (result === true) {
                        x34x26RealLinkEl.click();
                    }
                }
            })
        })
    }
    if (x40x305RealLinkEl && x40x305BtnEl) {
        x40x305BtnEl.addEventListener('click', function () {
            bootbox.confirm({
                title: 'Observera',
                message: bootBoxContactPrintMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Avbryt'
                    },
                    confirm: {
                        label: '<i class="fas fa-shopping-cart"></i> Lägg till i kundvagnen'
                    }
                },
                callback: function (result) {
                    if (result === true) {
                        x40x305RealLinkEl.click();
                    }
                }
            })
        })
    }
    if (x40x305RealLinkEl && x40x305BtnEl) {
        x40x305BtnEl.addEventListener('click', function () {
            bootbox.confirm({
                title: 'Observera',
                message: bootBoxContactPrintMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Avbryt'
                    },
                    confirm: {
                        label: '<i class="fas fa-shopping-cart"></i> Lägg till i kundvagnen'
                    }
                },
                callback: function (result) {
                    if (result === true) {
                        x40x305RealLinkEl.click();
                    }
                }
            })
        })
    }
    if (x40x30RealLinkEl && x40x30BtnEl) {
        x40x30BtnEl.addEventListener('click', function () {
            bootbox.confirm({
                title: 'Observera',
                message: bootBoxContactPrintMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Avbryt'
                    },
                    confirm: {
                        label: '<i class="fas fa-shopping-cart"></i> Lägg till i kundvagnen'
                    }
                },
                callback: function (result) {
                    if (result === true) {
                        x40x30RealLinkEl.click();
                    }
                }
            })
        })
    }
});
