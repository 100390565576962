const {Loader} = require('google-maps');
const loader = new Loader('AIzaSyA5v6l5Y1TmIuN1v3gnUhmyjfHRNI9V0xs', {});

$(document).on('turbolinks:load', function () {

    var photoSrcElem = document.getElementById('photoGoogleMapSrc');
    var photoMapElem = document.getElementById('photoGoogleMap');
    var loadGoogleMapsBtnElem = document.getElementById('loadGoogleMapsBtn');
    var expandMapBtnElem = document.getElementById('expandMapBtn');
    if (loadGoogleMapsBtnElem) {
        loadGoogleMapsBtnElem.addEventListener('click', function () {
            loadGoogleMapsBtnElem.disabled = true;
            if (photoSrcElem && photoSrcElem.innerText.length > 0 && loadGoogleMapsBtnElem && photoMapElem.dataset.hasLoaded === "false") {
                photoMapElem.style.display = "block";
                expandMapBtnElem.style.display = "block";
                photoMapElem.src = photoSrcElem.innerText;
                photoMapElem.dataset.hasLoaded = "true";
            }
        });
    }

    var formLocSugNewElem = document.getElementById('new_location_suggestion');
    var formLocSugNewText = document.getElementById('q_form_loc_sug_new');
    if (formLocSugNewElem && formLocSugNewText) {
        formLocSugNewText.addEventListener('input', function (event) {
            if (event.target.value === "") {
                formLocSugNewElem.querySelector('button').innerHTML = "Placera gården på kartan. Klicka här.";
                formLocSugNewElem.querySelector('button').classList.add('btn-info');
                formLocSugNewElem.querySelector('button').classList.remove('btn-primary');
            } else {
                formLocSugNewElem.querySelector('button').innerHTML = "Förhandsgranska inskrivna koordinater";
                formLocSugNewElem.querySelector('button').classList.remove('btn-info');
                formLocSugNewElem.querySelector('button').classList.add('btn-primary');
            }
        })
    }

    // New Location Suggesiton map(with marker that is draggable)
    var locationSuggestionNewMap = document.getElementById("map-box");
    if (locationSuggestionNewMap) {
        // var hasClicked = false;
        var latitude = 0;
        var longitude = 0;
        var base_latitude = 0;
        var base_longitude = 0;

        if (locationSuggestionNewMap.dataset.photoLatitude) {
            latitude = locationSuggestionNewMap.dataset.photoLatitude;
        }
        if (locationSuggestionNewMap.dataset.photoLongitude) {
            longitude = locationSuggestionNewMap.dataset.photoLongitude;
        }
        if (locationSuggestionNewMap.dataset.baseLatitude) {
            base_latitude = locationSuggestionNewMap.dataset.baseLatitude;
        }
        if (locationSuggestionNewMap.dataset.baseLongitude) {
            base_longitude = locationSuggestionNewMap.dataset.baseLongitude;
        }

        if (locationSuggestionNewMap && locationSuggestionNewMap.dataset.photoLatitude) {
            loader.load().then(function (google) {
                const map = new google.maps.Map(document.getElementById('map'), {
                    center: {
                        lat: Number(latitude),
                        lng: Number(longitude)
                    },
                    zoom: 8,
                    mapTypeId: 'hybrid'
                });

                if (base_latitude && base_longitude) {
                    new google.maps.Circle({
                        strokeColor: '#f3ff64',
                        strokeOpacity: 0.8,
                        strokeWeight: 1,
                        fillColor: '#f3ff64',
                        fillOpacity: 0.2,
                        map: map,
                        center: {lat: Number(base_latitude), lng: Number(base_longitude)},
                        radius: 20000
                    });
                }

                var marker = new google.maps.Marker({
                    position: {
                        lat: Number(latitude),
                        lng: Number(longitude)
                    },
                    animation: google.maps.Animation.DROP,
                    map: map,
                    draggable: true
                });
                marker.addListener('drag', function (event) {
                    updateFormLocation(event.latLng)
                });
            });


            // Update form attributes with given coordinates
            function updateFormLocation(latLng) {
                $('#location_suggestion_latitude').val(latLng.lat());
                $('#location_suggestion_longitude').val(latLng.lng());
            }
        }
    }

    // Albums :show map
    var albumShowMap = document.getElementById('albums-map-box');
    var albumShowMapCoords = document.getElementById('map-coords');
    if (albumShowMap) {
        loader.load().then(function (google) {
            var firstCoordEl = albumShowMapCoords.querySelectorAll('span')[0];
            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: firstCoordEl && firstCoordEl.dataset.lng ? 12 : 5,
                center: {lat: firstCoordEl && firstCoordEl.dataset.lat ? Number(firstCoordEl.dataset.lat) : 62.3858, lng: firstCoordEl && firstCoordEl.dataset.lng ? Number(firstCoordEl.dataset.lng) : 16.3220},
                mapTypeId: 'hybrid'
            });

            if (albumShowMapCoords) {
                var flightPlanCoords = [];
                albumShowMapCoords.querySelectorAll('span').forEach(function (el, index) {
                    var elData = el.dataset;
                    if (el && elData && elData.lat && elData.lng && elData.imgSrc && elData.position) {
                        var marker = new google.maps.Marker({
                            position: {
                                lat: Number(elData.lat),
                                lng: Number(elData.lng)
                            },
                            label: elData.position,
                            map: map
                        });
                        var infowindow = new google.maps.InfoWindow({
                            content: '<div><a href="/bilder/'+elData.photoId+'"><img src="'+elData.imgSrc+'" alt="flygfotohistoria bild"/></a></div>'
                        });
                        marker.addListener('click', function() {
                            infowindow.open(map, marker);
                        });
                        flightPlanCoords.push({lat: Number(elData.lat), lng: Number(elData.lng)})
                    }
                })
            }
            var flightPath = new google.maps.Polyline({
                path: flightPlanCoords,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2
            });

            flightPath.setMap(map);
        })
    }
});