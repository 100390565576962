/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//require("channels")
require("turbolinks").start()
require("@rails/ujs").start()
require("bootstrap");
window.moment = require('moment');
require("tempusdominus-bootstrap-4")
require("../stylesheets/application.scss")
const BigPicture = require('bigpicture');
import "cookieconsent";
import "cookieconsent/build/cookieconsent.min.css";
import './gmaps';
import './order';
import './admin';
import './charts';

$(document).on('turbolinks:load', function () {
    window.cookieconsent.initialise({
        container: document.getElementById("content"),
        palette: {
            popup: {background: "#fff"},
            button: {background: "#aa0000"},
        },
        revokable: true,
        onStatusChange: function (status) {
            console.log(this.hasConsented() ?
                'enable cookies' : 'disable cookies');
        },
    });
    var stripePaymentTriggerEl = document.getElementById('stripe_payment_trigger');
    if (stripePaymentTriggerEl && stripePaymentTriggerEl.dataset.checkoutId) {
        var stripe = Stripe('pk_live_51H89DXIRsYOc6SFACM6OQU6NxhXmYOntBTQs9UqLke7rgpq0ZCepuRsBtHFTTm7lmeO52VKbqbzjcwULVbjcH79s00aF7srV9c');

        stripe.redirectToCheckout({
            sessionId: stripePaymentTriggerEl.dataset.checkoutId,
        })
            .then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            });
    }

    document.querySelectorAll('a.bigpicture').forEach(function (el) {
        el.addEventListener('click', function (e) {
            bigPictureEventListener(e, el)
        })
    });

    $('#datetimepicker4').datetimepicker({
        format: 'L'
    });

    function bigPictureEventListener(e, el) {
        e.preventDefault();
        e.stopPropagation();
        if (el.dataset && el.dataset.bigpictureType && el.dataset.bigpictureType === 'iframe') {
            BigPicture({
                el: el,
                iframeSrc: el.href,
            })
        } else {
            BigPicture({
                el: el,
                imgSrc: el.href,
            })
        }
    }

    $(window).trigger('load.bs.select.data-api');
});
